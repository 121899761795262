import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setfUserLocation: ['userLocation'],
  setfSearchedLocation: ['searchedLocation'],
  setfSearchedDate: ['searchedDate'],
  setfCourses: ['courses'],
  setfTeeTimes: ['teeTimes'],
  setfRateTypes: ['rateTypes'],
  setfProvider: ['provider'],
  setfCheckout: ['checkout'],
  setfBookingConfirmation: ['bookingConfirmation'],
  resetFlow: [],
});

export const FlowTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  userLocation: '',
  searchedLocation: '',
  searchedDate: '',
  courses: {},
  teeTimes: {
    authToken: sessionStorage.getItem('teeTimesToken'),
    URL: sessionStorage.getItem('teeTimesURL'),
  },
  rateTypes: {
    authToken: sessionStorage.getItem('rateTypesToken'),
    URL: sessionStorage.getItem('rateTypesURL'),
  },
  provider: {
    authToken: sessionStorage.getItem('providerToken'),
    URL: sessionStorage.getItem('providerURL'),
  },
  checkout: {
    authToken: sessionStorage.getItem('checkoutToken'),
    URL: sessionStorage.getItem('checkoutURL'),
  },
  bookingConfirmation: {},
});

const setfUserLocation = (state, { userLocation }) => state.merge({ userLocation });
const setfSearchedLocation = (state, { searchedLocation }) => state.merge({ searchedLocation });
const setfSearchedDate = (state, { searchedDate }) => state.merge({ searchedDate });
const setfCourses = (state, { courses }) => state.merge({
  courses: { ...state.courses, ...courses },
});
const setfTeeTimes = (state, { teeTimes }) => {
  const nextState = { teeTimes: { ...state.teeTimes, ...teeTimes } };
  if (teeTimes.authToken) {
    nextState.rateTypes = { authToken: null };
    sessionStorage.setItem('rateTypesToken', null);
    nextState.provider = { authToken: null };
    sessionStorage.setItem('providerToken', null);
    nextState.checkout = { authToken: null };
    sessionStorage.setItem('checkoutToken', null);
  }
  if (teeTimes.URL) {
    nextState.rateTypes = { URL: null };
    sessionStorage.setItem('rateTypesURL', null);
    nextState.provider = { URL: null };
    sessionStorage.setItem('providerURL', null);
    nextState.checkout = { URL: null };
    sessionStorage.setItem('checkoutURL', null);
  }
  return state.merge(nextState);
};
const setfRateTypes = (state, { rateTypes }) => {
  const nextState = { rateTypes: { ...state.rateTypes, ...rateTypes } };
  if (rateTypes.authToken) {
    nextState.provider = { authToken: null };
    sessionStorage.setItem('providerToken', null);
    nextState.checkout = { authToken: null };
    sessionStorage.setItem('checkoutToken', null);
  }
  if (rateTypes.URL) {
    nextState.provider = { URL: null };
    sessionStorage.setItem('providerURL', null);
    nextState.checkout = { URL: null };
    sessionStorage.setItem('checkoutURL', null);
  }
  return state.merge(nextState);
};
const setfProvider = (state, { provider }) => {
  const nextState = { provider: { ...state.provider, ...provider } };
  if (provider.authToken) {
    nextState.checkout = { authToken: null };
    sessionStorage.setItem('checkoutToken', null);
  }
  if (provider.URL) {
    nextState.checkout = { URL: null };
    sessionStorage.setItem('checkoutURL', null);
  }
  return state.merge(nextState);
};
const setfCheckout = (state, { checkout }) => state.merge({
  checkout: { ...state.checkout, ...checkout },
});
const setfBookingConfirmation = (state, { bookingConfirmation }) => state.merge({
  bookingConfirmation: { ...state.bookingConfirmation, ...bookingConfirmation },
});

const resetFlow = (state) => {
  sessionStorage.setItem('rateTypesToken', null);
  sessionStorage.setItem('providerToken', null);
  sessionStorage.setItem('checkoutToken', null);
  sessionStorage.setItem('rateTypesURL', null);
  sessionStorage.setItem('providerURL', null);
  sessionStorage.setItem('checkoutURL', null);
  return state.merge({
    ...INITIAL_STATE,
    teeTimes: { authToken: null, URL: null },
    provider: { authToken: null, URL: null },
    rateTypes: { authToken: null, URL: null },
    checkout: { authToken: null, URL: null },
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SETF_USER_LOCATION]: setfUserLocation,
  [Types.SETF_SEARCHED_LOCATION]: setfSearchedLocation,
  [Types.SETF_SEARCHED_DATE]: setfSearchedDate,
  [Types.SETF_COURSES]: setfCourses,
  [Types.SETF_TEE_TIMES]: setfTeeTimes,
  [Types.SETF_RATE_TYPES]: setfRateTypes,
  [Types.SETF_PROVIDER]: setfProvider,
  [Types.SETF_CHECKOUT]: setfCheckout,
  [Types.SETF_BOOKING_CONFIRMATION]: setfBookingConfirmation,
  [Types.RESET_FLOW]: resetFlow,
});
