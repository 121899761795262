const TIME_SLOT_PAGINATION = 15;

export const BANNED_ERROR_MESSAGE = 'You have been flagged by our site moderators. Please contact support for further assistance';

export const HEADER_ROW = {
  id: 0,
  slug: 'header',
  type: 'header',
};

export const ERROR_ROW = {
  id: 0,
  slug: 'ERROR',
  type: 'error',
};

export const CURRENT_LOCATION = {
  id: 0,
  slug: 'Current Location',
  label: 'Current Location',
  type: 'Current',
  distance: 0,
};

export const SORT_COURSES_LABEL = [
  {
    label: 'Best Match',
    displayLabel: 'Best Match',
    value: 'best-match',
  }, {
    label: 'Nearest',
    displayLabel: 'Nearest',
    value: 'distance',
  }, {
    label: 'Price',
    displayLabel: 'Price',
    value: 'price-lowest-first',
  },
  {
    label: 'Rating',
    displayLabel: 'Rating',
    value: 'rating',
  }, {
    label: 'A - Z',
    displayLabel: 'A - Z',
    value: 'name-asc',
  }, {
    label: 'Z - A',
    displayLabel: 'Z - A',
    value: 'name-dsc',
  },
];

export const GOLFBOOK = 'GolfBook';
export const GOLFNOW = 'GolfNow';
export const TEEOFF = 'TeeOff';
export const GOLFZING = 'GolfZing';

export const MIN_LOCATION_FILTER = 0;
export const MAX_LOCATION_FILTER = 150;
export const MIN_PRICE_FILTER = 0;
export const MAX_PRICE_FILTER = 500;
export const MIN_TIME_FILTER = 5;
export const MAX_TIME_FILTER = 21;

export const DEALS_PAGINATION = 10;
export const SECTION_COURSES_PAGINATION = 15;
export const SELLABLE_ENTITIES = ['Course', 'Deal', 'Banner'];

export const DESKTOP_SIZE = 'desktop';
export const TABLET_SIZE = 'tablet';
export const MOBILE_SIZE = 'mobile';

export const IS_9_HOLES = 'is_9_holes';
export const IS_18_HOLES = 'is_18_holes';

export const IS_WALKING = 'is_walking';
export const IS_RIDING = 'is_riding';

export const MAX_MAP_ZOOM_IN = 4;
export const MAX_MAP_ZOOM_OUT = 22;

export const PLAYERS_RANGE = [1, 2, 3, 4, 5, 6];

export const LIGHTNING_RATE = 'is_lightning_rate';

export const CART_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Show all', value: null },
];

export const HOLES_OPTIONS = [
  { label: 'Any', displayLabel: 'Any', value: null },
  { label: '9', displayLabel: '9', value: 9 },
  { label: '18', displayLabel: '18', value: 18 },
];

export const PLAYERS_OPTIONS = [
  { label: 'Any', displayLabel: 'Any', value: null },
  { label: '1', displayLabel: '1', value: 1 },
  { label: '2', displayLabel: '2', value: 2 },
  { label: '3', displayLabel: '3', value: 3 },
  { label: '4', displayLabel: '4', value: 4 },
  { label: '5', displayLabel: '5', value: 5 },
  { label: '6', displayLabel: '6', value: 6 },
];

export const RATE_TYPE_OPTIONS = [
  {
    label: 'Specials',
    displayLabel: 'Specials',
    value: LIGHTNING_RATE,
  }, {
    label: 'Online',
    displayLabel: 'Online',
    value: 'is_regular_rate',
  }, {
    label: 'Junior',
    displayLabel: 'Junior',
    value: 'is_junior_rate',
  }, {
    label: 'Senior',
    displayLabel: 'Senior',
    value: 'is_senior_rate',
  }, {
    label: 'Resident',
    displayLabel: 'Resident',
    value: 'is_resident_rate',
  }, {
    label: 'Member',
    displayLabel: 'Member',
    value: 'is_member_rate',
  }, {
    label: 'Military',
    displayLabel: 'Military',
    value: 'is_military_rate',
  },
];

export const SPECIAL_RATE_TYPES = [
  'junior',
  'military',
  'resident',
  'senior',
];

export const MEMBER_RATE_TYPE = 'member';

export const USA_MAP_BOUNDING_BOX = [[-126.439548, 19.414309], [-66.978592, 54.067097]];

export const MIN_HANDICAP = -5;
export const MAX_HANDICAP = 40.4;

export const UNALLOWED_TEE_TIMES_ERROR_CODES = [
  403,
];

export const UNAVAILABLE_TEE_TIMES_ERROR_CODE = 404;

export const INVALID_TEE_TIMES_ERROR_CODE = 422;

export const BOOKING_LIMIT_ERROR_CODE = 412;

export const PAYMENTS_HISTORY_PAGINATION = 6;

export const GTM_USER_SIGN_UP_VALUE = 1.73;

export const MEMBERSHIP_CANCELLATION_REQ_CODE = 2;

export default { TIME_SLOT_PAGINATION };

export const REVIEWS_SORT_OPTIONS = [
  {
    label: 'Newest',
    displayLabel: 'Newest',
    value: 'most-recent',
  },
  {
    label: 'Highest rated',
    displayLabel: 'Highest rated',
    value: 'most_favorable',
  },
  {
    label: 'Lowest rated',
    displayLabel: 'Lowest rated',
    value: 'most_critical',
  },
];

export const COMMUNICATION_PREFERENCES = {
  promotional: {
    url: 'promotional',
    optionId: 'promotional_opt_out',
    title: 'Marketing & Promotional Offers',
    titleMobile: 'Marketing & promos',
    subtitle: 'Exclusive offers, discounts and tee time bonuses',
    subtitleMobile: 'Exclusive offers, promotions, and updates.',
    description: `You are about to unsubscribe from future Marketing & Promotional emails by clicking on the “Unsubscribe” button below. To unsubscribe from more or all of ${process.env.REACT_APP_SHORT_NAME}’s marketing emails, please check the appropriate boxes below. You will still receive service and reservation-related emails, including account updates.`,
  },
  weekly: {
    url: 'weekly',
    optionId: 'weekly_opt_out',
    title: 'Weekly Deals',
    titleMobile: 'Weekly deals',
    subtitle: 'Offers from courses near you',
    subtitleMobile: 'Golf deals and offers relevant to you.',
    description: `You are about to unsubscribe from future Weekly Deals emails by clicking on the “Unsubscribe” button below. To unsubscribe from more or all of ${process.env.REACT_APP_SHORT_NAME}’s marketing emails, please check the appropriate boxes below. You will still receive service and reservation-related emails, including account updates.`,
  },
  contestsAndSweepstakes: {
    url: 'contests-and-sweepstakes',
    optionId: 'contests_and_sweepstakes_opt_out',
    title: 'Contests & Sweepstakes',
    titleMobile: 'Contests & sweepstakes',
    subtitle: 'Win trips, deals, merch, and more!',
    subtitleMobile: 'Opportunities to win merchandise, trips, deals, and more.',
    description: `You are about to unsubscribe from future Contests & Sweepstakes emails by clicking on the “Unsubscribe” button below. To unsubscribe from more or all of ${process.env.REACT_APP_SHORT_NAME}’s marketing emails, please check the appropriate boxes below. You will still receive service and reservation-related emails, including account updates.`,
  },
  newsletter: {
    url: 'newsletter',
    optionId: 'newsletter_opt_out',
    title: 'Newsletters',
    titleMobile: 'Newsletters',
    subtitle: `Stay updated with ${process.env.REACT_APP_FRIENDLY_NAME}`,
    subtitleMobile: 'Stay up-to-date with the golf industry.',
    description: `You are about to unsubscribe from future Newsletters emails by clicking on the “Unsubscribe” button below. To unsubscribe from more or all of ${process.env.REACT_APP_SHORT_NAME}’s marketing emails, please check the appropriate boxes below. You will still receive service and reservation-related emails, including account updates.`,
  },
};

export const DEFAULT_COUNTRY_ISO2 = 'US';

export const SUPPORT_REASON_OPTIONS = [
  {
    label: 'Reservation/TeeTime',
    value: 'Reservation/TeeTime',
  },
  {
    label: 'Account',
    value: 'Account',
  },
  {
    label: 'General/Other',
    value: 'General/Other',
  },
];

export const MICROSITES = [
  { name: 'supremeGolf', shortName: 'sg' },
  { name: 'barstool', shortName: 'bt' },
  { name: 'golfBook', shortName: 'gb' },
  { name: 'golfZing', shortName: 'gz' },
  { name: 'golfDigest', shortName: 'gd' },
  { name: 'greatLife', shortName: 'gl' },
  { name: 'foldsOfHonor', shortName: 'foh' },
  { name: 'theWeatherChannel', shortName: 'twc' },
];

export const INTERNAL_CHECKOUT_ERROR_MESSAGES = [
  'You have reached the threshold for reservations within a timespan. Please try again later.',
];

export const GUEST_BLOCKED_MESSAGE = 'You have been flagged for suspicious activity. Please contact our support team for additional assistance.';

export const NETWORK_MEMBERSHIP_BANNER_DEFAULT_RANGE = 11;
