import {
  call, put, takeLatest, select, getContext,
} from 'redux-saga/effects';
import qs from 'qs';
import { push } from 'react-router-redux';
import CheckoutActions, { CheckoutTypes, REEDEM_TYPE } from 'reducers/checkout';
import SearchParamsActions from 'reducers/searchParams';
import flowActions from 'reducers/flow';
import ReservationsApi from 'apis/supremeGolfApi/ReservationsApi';
import {
  BOOKING_CONFIRMATION,
  BOOKING_LIMIT_REACHED,
  TEE_TIME_ERROR,
  TEE_TIME_NOT_AVAILABLE,
  TEE_TIME_NOT_ALLOWED,
} from 'utils/routes';
import {
  BOOKING_LIMIT_ERROR_CODE,
  UNALLOWED_TEE_TIMES_ERROR_CODES,
  UNAVAILABLE_TEE_TIMES_ERROR_CODE,
  INTERNAL_CHECKOUT_ERROR_MESSAGES,
} from 'utils/constants';
import { CVV_MISSING_ERROR_CODE } from 'utils/errorCodes';

const VALIDATION_LIMIT_REACHED = 429;

function getDefaultTeeTimeQueryParams({ location }, filterValues) {
  const defaultParams = {
    location,
    cart: filterValues.cart,
    date: filterValues.dateFilter,
    dealsOnly: filterValues.dealsOnly,
    holes: filterValues.holesFilter,
    locationRange: filterValues.locationRange,
    players: filterValues.playersFilter,
    previousRateTypes: filterValues.previousRateTypes,
    price: filterValues.price,
    rate: filterValues.rate,
    time: filterValues.time,
    timeSlot: filterValues.timeSlot,
  };
  return defaultParams;
}

function prepareReservationData(receipt) {
  return {
    transactionId: `TT-${receipt.offer.teeTimeReservationId}`,
    bookingtype: receipt.offer.teeTime.isHotDeal ? 'hotdeal' : 'rack',
    transactionTotal: receipt.offer.sgRevenue.total,
    transactionProducts: [{
      item_id: receipt.offer.teeTime.id.toString(),
      item_name: 'TeeTime',
      price: receipt.offer.sgRevenue.price,
      quantity: receipt.offer.qty,
    }],
  };
}

const unallowedErrorCode = (errorCode) => (
  UNALLOWED_TEE_TIMES_ERROR_CODES.some((code) => code === errorCode)
);

const unavailableErrorCode = (errorCode) => (
  UNAVAILABLE_TEE_TIMES_ERROR_CODE === errorCode
);

const bookingLimitErrorCode = (errorCode) => (
  BOOKING_LIMIT_ERROR_CODE === errorCode
);

export function* teeTimeFailure(responseCode, extraParams = {}) {
  const searchValues = yield select((state) => state.search.searchParams.searchValues);
  const filterValues = yield select((state) => state.search.searchParams.filterValues);
  const defaultQueryParams = getDefaultTeeTimeQueryParams(searchValues, filterValues);
  const { selectedRateType, skipRate } = filterValues;

  const queryString = qs.stringify({
    ...defaultQueryParams,
    selectedRateType,
    ...extraParams,
    skipRate,
  });

  if (unallowedErrorCode(responseCode)) {
    yield put(push(`${TEE_TIME_NOT_ALLOWED}?${queryString}`));
  } else if (bookingLimitErrorCode(responseCode)) {
    yield put(push(`${BOOKING_LIMIT_REACHED}?${queryString}`));
  } else if (unavailableErrorCode(responseCode)) {
    yield put(push(`${TEE_TIME_NOT_AVAILABLE}?${queryString}`));
  } else if (responseCode === TEE_TIME_ERROR) {
    yield put(push(`${TEE_TIME_ERROR}?${queryString}`));
  }
}

export function* getPrepareHandler({ params }) {
  try {
    const {
      id: teeTimeId,
      players: qty,
      prepaymentRuleId,
      internalOrigin,
    } = params;

    const LS = yield getContext('localStorage');
    const utmParams = yield call(LS.getUtmParams);
    const rwgParams = yield call(LS.getRwgParams);
    if (internalOrigin) utmParams.utmInternalOrigin = internalOrigin;
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams);
    const creditCardSelected = yield select((state) => state.checkout.creditCard);
    const applyBookingFeeCredit = yield select((state) => state.checkout.applyBookingFeeCredit);

    const providerToken = yield select(({ flow }) => flow?.provider?.authToken);
    const {
      creditCard, preparedTeeTime, features, checkoutDisclaimer, authToken,
    } = yield call(
      ReservationsApi.prepareCheckout,
      {
        qty,
        applyBookingFeeCredit,
        teeTimeId,
        prepaymentRuleId,
        utmParams,
        iterableTracking,
        rwgParams,
      },
      providerToken,
    );
    yield put(flowActions.setfCheckout({ authToken }));
    sessionStorage.setItem('checkoutToken', authToken);

    if (preparedTeeTime.teeTime === null) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE);
    } else {
      yield put(CheckoutActions.setCartAndHoles(
        preparedTeeTime?.teeTime?.isRiding,
        preparedTeeTime?.teeTime?.numHoles,
      ));
      yield put(CheckoutActions.getPrepareDone(
        creditCardSelected || creditCard,
        preparedTeeTime,
        features,
        checkoutDisclaimer,
      ));
      yield put(CheckoutActions.setPromoCode(preparedTeeTime.promoCode ?? ''));
    }
  } catch (error) {
    const { response: { data: { error: responseError } = {} } = {} } = error;
    const { response: { status: responseCode } = {} } = error;
    const errorMessage = responseError || error.message;

    if (responseError === 'Authorization Failed') {
      yield put(SearchParamsActions.performSearch());
      return;
    }

    if (bookingLimitErrorCode(responseCode)) {
      yield* teeTimeFailure(responseCode, { errorMessage });
    } else if (responseCode) {
      if (unavailableErrorCode(responseCode)) yield* teeTimeFailure(responseCode);
      else yield* teeTimeFailure(responseCode, { errorMessage });
    } else {
      yield put(CheckoutActions.getPrepareError(errorMessage));
    }
  }
}

function* updatePrepareAndPerformCheckoutPreperation(params) {
  const creditCard = yield select((state) => state.checkout.creditCard);
  const features = yield select((state) => state.checkout.features);
  const applyBookingFeeCredit = yield select((state) => state.checkout.applyBookingFeeCredit);
  const donationAmount = yield select((state) => state.checkout.donationAmount);

  const checkoutDisclaimer = yield select((state) => state.checkout.checkoutDisclaimer);

  const providerToken = yield select(({ flow }) => flow?.provider?.authToken);
  const updatedParams = { ...params, applyBookingFeeCredit };
  if (donationAmount) updatedParams.donationAmount = donationAmount;
  const { preparedTeeTime, authToken } = yield call(
    ReservationsApi.updatePrepareCheckout,
    updatedParams,
    providerToken,
  );
  yield put(flowActions.setfCheckout({ authToken }));
  sessionStorage.setItem('checkoutToken', authToken);

  if (preparedTeeTime.teeTime === null) {
    yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE);
  } else {
    yield put(CheckoutActions.setCartAndHoles(
      preparedTeeTime?.teeTime?.isRiding,
      preparedTeeTime?.teeTime?.numHoles,
    ));
    yield put(CheckoutActions.getPrepareDone(
      creditCard,
      preparedTeeTime,
      features,
      checkoutDisclaimer,
    ));
    yield put(CheckoutActions.setUpdatePrepareRecent(true));
    yield put(SearchParamsActions.performCheckoutPreparation(
      params.teeTimeId,
      params.prepaymentRuleId,
      undefined,
      preparedTeeTime.teeTime.provider,
      preparedTeeTime.teeTime.sourceProvider,
    ));
  }
}

export function* updatePrepareHandler(action) {
  try {
    const params = {
      qty: action.players,
      teeTimeId: action.teeTimeId,
      token: action.teeTimeReservationId,
      promoCode: action.promoCode,
      redeemLoyaltyPoints: action.rewardsPointsAmount,
      giftCard: {
        code: action.giftCardCode,
        amount: action.giftCardAmount,
        cvv: action.giftCardCvv,
      },
      prepaymentRuleId: action.prepaymentRuleId,
    };
    yield updatePrepareAndPerformCheckoutPreperation(params);
  } catch (error) {
    const {
      response: {
        data: { error: explanatoryError, code },
        status: responseCode,
      },
    } = error;
    const errorMessage = explanatoryError || error.message;
    if (code === CVV_MISSING_ERROR_CODE) yield put(CheckoutActions.setIsGiftCardCvvRequired(true));

    if (bookingLimitErrorCode(responseCode)) {
      yield* teeTimeFailure(responseCode, { errorMessage });
    }
    // if (errorMessage === 'Invalid promo code') {
    //   yield put(CheckoutActions.setPromoCode(''));
    // }
    yield put(CheckoutActions.getPrepareError(explanatoryError || error.message));
  }
}

export function* makePaymentHandler(action) {
  const gtm = yield getContext('gtm');
  const {
    searchParams: {
      throughSection,
    },
  } = yield select((state) => state.search);
  try {
    const {
      token, creditCardId,
    } = action;

    const checkoutToken = yield select(({ flow }) => flow?.checkout?.authToken);
    const receipt = yield call(
      ReservationsApi.makePayment,
      {
        token,
        creditCardId,
        throughSection,
      },
      checkoutToken,
    );

    yield put(SearchParamsActions.setThroughSection(''));
    yield put(CheckoutActions.makePaymentDone(receipt));

    const {
      receipt: {
        offer: {
          teeTimeReservationId,
        },
      },
    } = receipt;

    yield call(gtm.changeDataLayer, prepareReservationData(receipt.receipt));

    yield call(gtm.trackEvent, {
      eventCategory: 'booking',
      eventAction: 'success',
      eventLabel: teeTimeReservationId,
      event: 'booking-success',
    });

    yield call(gtm.removeTransactionData);
    yield put(CheckoutActions.resetCheckout());
    yield put(flowActions.resetFlow());
    yield put(push(BOOKING_CONFIRMATION.replace(':reservationId', teeTimeReservationId)));
  } catch (error) {
    const {
      response: {
        data: {
          error: explanatoryError,
          isReservationUnconfirmed: isRecoverable,
          reservationId,
          reservationUrl,
          isPrepaidOnProvider,
        },
      },
    } = error;
    const errorMessage = explanatoryError || error.message;

    yield call(gtm.trackEvent, {
      eventCategory: 'booking',
      eventAction: 'failure',
      eventLabel: reservationId || 'no reservation id',
      event: 'booking-failure',
    });

    yield put(CheckoutActions.makePaymentError(errorMessage));

    if (explanatoryError === 'Authorization Failed') {
      yield put(SearchParamsActions.performSearch());
      return;
    }

    if (!isRecoverable && isPrepaidOnProvider) {
      yield* teeTimeFailure(TEE_TIME_ERROR, { reservationUrl });
    } else if (!isRecoverable && INTERNAL_CHECKOUT_ERROR_MESSAGES.includes(errorMessage)) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE, { errorMessage });
    } else if (!isRecoverable) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE);
    }
  }
}

export function* requestValidateRewardsHandler({ redeemType, cb }) {
  try {
    const {
      promoCode,
      rewardsPointsAmount: redeemLoyaltyPoints,
      preparedTeeTime: {
        teeTimeId,
        teeTimeReservationId: token,
        teeTime: {
          prepaymentRuleId,
        },
      },
      giftCardCode,
      giftCardAmount,
      giftCardCvv,
    } = yield select((state) => state.checkout);
    const {
      searchParams: {
        filterValues:
        {
          playersFilter: qty,
        },
      },
    } = yield select((state) => state.search);

    yield updatePrepareAndPerformCheckoutPreperation({
      qty,
      teeTimeId,
      token,
      promoCode,
      redeemLoyaltyPoints,
      giftCard: {
        code: giftCardCode,
        amount: giftCardAmount,
        cvv: giftCardCvv,
      },
      prepaymentRuleId,
    });
    if (cb) cb({ isSuccess: true });
  } catch (error) {
    const {
      response: { data: { error: explanatoryError, code } },
    } = error;

    const errorText = explanatoryError || error.message;
    const errorMessage = {
      validatePromoCodeError: redeemType === REEDEM_TYPE.promoCode ? errorText : '',
      validateRewardsError: redeemType === REEDEM_TYPE.rewards ? errorText : '',
      validateGiftCardError: redeemType === REEDEM_TYPE.giftCard ? errorText : '',
    };
    let reset = false;
    if (error?.response?.status === VALIDATION_LIMIT_REACHED) {
      yield put(CheckoutActions.setIsValidationLimitReached(true));
      reset = true;
    }

    if (code === CVV_MISSING_ERROR_CODE) {
      yield put(CheckoutActions.setIsGiftCardCvvRequired(true));
    } else {
      yield put(CheckoutActions.requestValidateRedeemError(errorMessage));
    }
    if (cb) cb({ isSuccess: false, reset });
  }
}

function* getPrepareWatcher() {
  yield takeLatest(CheckoutTypes.GET_PREPARE, getPrepareHandler);
}

function* updatePrepareWatcher() {
  yield takeLatest(CheckoutTypes.UPDATE_PREPARE, updatePrepareHandler);
}

function* makePaymentWatcher() {
  yield takeLatest(CheckoutTypes.MAKE_PAYMENT, makePaymentHandler);
}

function* requestValidateRewardsWatcher() {
  yield takeLatest(CheckoutTypes.REQUEST_VALIDATE_REDEEM, requestValidateRewardsHandler);
}

export default [
  getPrepareWatcher,
  updatePrepareWatcher,
  makePaymentWatcher,
  requestValidateRewardsWatcher,
];
