import SupremeGolfApiClient from './SupremeGolfApiClient';

const GUEST_ENDPOINT = 'v6/guests';

class GuestApi {
  static async validateEmail({ email }) {
    const response = await SupremeGolfApiClient.get(`${GUEST_ENDPOINT}/validate-email`, { email });
    return response.data;
  }

  static async addGuestCreditCard({ token, gRecaptchaResponseData }) {
    const response = await SupremeGolfApiClient.post(`${GUEST_ENDPOINT}/credit_cards`, {
      token, gRecaptchaResponseData,
    });
    return response.data;
  }

  static async makeGuestPayment({
    token,
    creditCardAccessToken,
    authToken,
    email,
    firstName,
    lastName,
  }) {
    const response = await SupremeGolfApiClient.post(`${GUEST_ENDPOINT}/reservations`, {
      token, creditCardAccessToken, authToken, email, firstName, lastName,
    });
    return response.data;
  }
}

export default GuestApi;
