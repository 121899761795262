import SupremeGolfApiClient from './SupremeGolfApiClient';

const RESERVATIONS_ENDPOINT = 'v6/reservations';
const CANCEL_RESERVATION_ENDPOINT = `${RESERVATIONS_ENDPOINT}/cancel`;

const TEE_TIME_RESERVATIONS_ENDPOINT_PREFIX = 'v6/tee_times';
const TEE_TIME_RESERVATIONS_ENDPOINT_SUFFIX = 'reservations';

class ReservationsApi {
  static async getReservations({ status, sortBy, order }) {
    const response = await SupremeGolfApiClient.get(RESERVATIONS_ENDPOINT, {
      shared: true,
      status,
      sortBy,
      order,
      perPage: 10000,
    });
    const { data } = response;

    return data.reservations;
  }

  static async getReservation({ id, accessToken }) {
    const payload = {};
    if (accessToken) payload.accessToken = accessToken;

    const response = await SupremeGolfApiClient.get(`${RESERVATIONS_ENDPOINT}/${id}`, payload);
    const { data } = response;

    return data;
  }

  static async postCancelReservation({ id, accessToken }) {
    const payload = {};
    if (accessToken) payload.accessToken = accessToken;
    await SupremeGolfApiClient.post(`${CANCEL_RESERVATION_ENDPOINT}/${id}`, payload);
    const response = await SupremeGolfApiClient.get(`${RESERVATIONS_ENDPOINT}/${id}`, payload);
    const { data } = response;

    return data;
  }

  static async postCancellationFeedback({ id, userMessage }) {
    const response = await SupremeGolfApiClient.post(
      `${RESERVATIONS_ENDPOINT}/${id}/create_teamwork_ticket`,
      {
        message: userMessage,
      },
    );

    return response;
  }

  static async prepareCheckout({
    qty,
    applyBookingFeeCredit,
    teeTimeId,
    prepaymentRuleId,
    iterableTracking,
    utmParams = {},
    utmInternalOrigin,
    rwgParams,
  }, authToken) {
    const url = `${TEE_TIME_RESERVATIONS_ENDPOINT_PREFIX}/${teeTimeId}/${TEE_TIME_RESERVATIONS_ENDPOINT_SUFFIX}/prepare`;
    const payload = {
      authToken,
      teeTimeId,
      qty,
      applyBookingFeeCredit,
      withDefaultCreditCard: true,
      prepaymentRuleId,
      iterableTracking,
      utmInternalOrigin,
      ...utmParams,
    };

    if (rwgParams?.rwgToken) {
      payload.rwgToken = rwgParams.rwgToken;
      payload.rwgTokenCreatedAt = rwgParams.rwgTokenCreatedAt;
    }
    if (rwgParams?.rwgCourseId) payload.rwgCourseId = rwgParams.rwgCourseId;

    const response = await SupremeGolfApiClient.post(url, payload);
    return response.data;
  }

  static async makePayment({ token, creditCardId, throughSection }, authToken) {
    const url = `${RESERVATIONS_ENDPOINT}/${token}`;
    const response = await SupremeGolfApiClient.post(url, {
      authToken,
      creditCardId,
      throughSection,
    });
    return response.data;
  }

  static async updatePrepareCheckout({
    qty,
    applyBookingFeeCredit,
    teeTimeId,
    token,
    promoCode,
    redeemLoyaltyPoints,
    giftCard,
    prepaymentRuleId,
    donationAmount,
  }, authToken) {
    const url = `${TEE_TIME_RESERVATIONS_ENDPOINT_PREFIX}/${teeTimeId}/${TEE_TIME_RESERVATIONS_ENDPOINT_SUFFIX}/prepare/${token}`;
    const payload = {
      authToken,
      teeTimeId,
      qty,
      applyBookingFeeCredit,
      token,
      promoCode,
      redeemLoyaltyPoints,
      giftCard,
      prepaymentRuleId,
    };
    if (donationAmount) payload.donation = donationAmount;
    const response = await SupremeGolfApiClient.patch(url, payload);

    return { preparedTeeTime: response.data.offer, authToken: response.data.authToken };
  }

  static async updatePlayersPrepare({ id, qty }) {
    const response = await SupremeGolfApiClient.post(`${RESERVATIONS_ENDPOINT}/${id}/prepare`, { qty });
    return response.data;
  }

  static async updateReservation({ id, qty, creditCardId }) {
    const response = await SupremeGolfApiClient.put(`${RESERVATIONS_ENDPOINT}/${id}`, {
      qty,
      creditCardId,
    });
    return response.data;
  }
}

export default ReservationsApi;
