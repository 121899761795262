import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import GuestApi from 'apis/supremeGolfApi/GuestApi';
import GuestActions, { GuestTypes } from 'reducers/guest';
import CheckoutActions from 'reducers/checkout';
import FlowActions from 'reducers/flow';
import { teeTimeFailure } from 'sagas/checkout';

import { BOOKING_CONFIRMATION, TEE_TIME_ERROR } from 'utils/routes';
import { GUEST_BLOCKED_MESSAGE, INTERNAL_CHECKOUT_ERROR_MESSAGES, UNAVAILABLE_TEE_TIMES_ERROR_CODE } from 'utils/constants';

export function* requestValidateGuestEmail({ email, cb }) {
  try {
    yield call(GuestApi.validateEmail, { email });
    yield put(GuestActions.validateGuestEmailSuccess());
    cb();
  } catch (error) {
    const errorMessage = error?.response?.data?.error ?? error?.message;
    yield put(GuestActions.validateGuestEmailError(errorMessage));
    if (cb) cb(errorMessage);
  }
}

export function* createGuestCreditCard({ token, gRecaptchaResponseData }) {
  try {
    const data = yield call(GuestApi.addGuestCreditCard, token, gRecaptchaResponseData);
    const { creditCard } = data;
    yield put(GuestActions.createGuestCreditCardDone(creditCard));
  } catch (error) {
    const errorMessage = error?.response?.data?.message ?? error?.message;
    yield put(GuestActions.createGuestCreditCardError(errorMessage));
  }
}

export function* makeGuestPayment({
  token,
  email,
  firstName,
  lastName,
}) {
  try {
    const authToken = yield select(({ flow }) => flow?.checkout?.authToken);
    const { accessToken } = yield select(({ checkout }) => checkout.creditCard);
    const data = yield call(GuestApi.makeGuestPayment, {
      token, email, firstName, lastName, authToken, creditCardAccessToken: accessToken,
    });
    yield put(GuestActions.makeGuestPaymentDone(data.receipt));
    yield put(CheckoutActions.resetCheckout());
    yield put(FlowActions.resetFlow());
    const { receipt: { accessToken: guestToken, offer: { teeTimeReservationId } } } = data;
    yield put(push(`${BOOKING_CONFIRMATION.replace(':reservationId', teeTimeReservationId)}?accessToken=${guestToken}`));
  } catch (error) {
    const {
      response: {
        data: {
          error: explanatoryError,
          isReservationUnconfirmed: isRecoverable,
          reservationUrl,
          isPrepaidOnProvider,
        },
      },
    } = error || {};
    const errorMessage = explanatoryError || error.message;
    yield put(GuestActions.makeGuestPaymentError(errorMessage));

    if (!isRecoverable && isPrepaidOnProvider) {
      yield* teeTimeFailure(TEE_TIME_ERROR, { reservationUrl });
    } else if (!isRecoverable && INTERNAL_CHECKOUT_ERROR_MESSAGES.includes(errorMessage)) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE, { errorMessage });
    } else if (!isRecoverable && errorMessage !== GUEST_BLOCKED_MESSAGE) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE);
    }
  }
}

function* requestValidateGuestEmailWatcher() {
  yield takeLatest(GuestTypes.VALIDATE_GUEST_EMAIL, requestValidateGuestEmail);
}

function* createGuestCreditCardWatcher() {
  yield takeLatest(GuestTypes.CREATE_GUEST_CREDIT_CARD, createGuestCreditCard);
}

function* makeGuestPaymentWatcher() {
  yield takeLatest(GuestTypes.MAKE_GUEST_PAYMENT, makeGuestPayment);
}

export default [
  requestValidateGuestEmailWatcher,
  createGuestCreditCardWatcher,
  makeGuestPaymentWatcher,
];
