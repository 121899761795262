import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import {
  UPCOMING_TAB_ID,
  PAST_TAB_ID,
  UPCOMING,
  PAST,
  tabIdFromStatus,
} from 'utils/bookingStatus';

const { Types, Creators } = createActions({
  getReservations: ['status'],
  getReservationsSilent: ['status'],
  getReservationsDone: ['status', 'reservations'],
  getReservationsError: ['message'],
  getReservation: ['id', 'status', 'accessToken'],
  getReservationSilent: ['id', 'status', 'accessToken'],
  getReservationDone: ['reservation'],
  getReservationError: ['message'],
  setBookingsTab: ['selectedTabId'],
  setSortByReservations: ['sortBy'],
  postCancelReservation: ['id', 'cb'],
  postCancelReservationDone: ['reservation'],
  postCancelReservationError: ['message'],
  postCancellationFeedback: ['id', 'userMessage'],
  postCancellationFeedbackDone: ['message'],
  postCancellationFeedbackError: ['message'],
  goToHome: null,
  prepareUpdateReservation: ['id', 'qty', 'cb'],
  prepareUpdateReservationDone: ['updatedReservation'],
  prepareUpdateReservationError: ['errorMessage'],
  updateReservation: ['id', 'qty', 'creditCardId', 'cb'],
  updateReservationDone: [],
  updateReservationError: ['errorMessage'],
  resetUpdateReservation: null,
});

export const ReservationsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  upcomingReservations: [],
  pastReservations: [],
  cancelledReservations: [],
  reservation: {},
  feedbackErrorMessage: '',
  cancellationFeedbackSuccess: false,
  selectedTabId: UPCOMING_TAB_ID,
  sortBy: 'date',
  updatedReservation: {},
  isUpdatingReservation: false,
  isUpdatingReservationDone: false,
  updatingReservationError: '',
});

const getReservations = (state, { status }) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  selectedTabId: tabIdFromStatus(status),
});

const getReservationsSilent = (state, { status }) => state.merge({
  isError: false,
  selectedTabId: tabIdFromStatus(status),
});

const getReservationsDone = (state, {
  status, reservations,
}) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  ...(status === UPCOMING && {
    upcomingReservations: reservations,
    selectedTabId: UPCOMING_TAB_ID,
  }),
  ...(status === PAST && {
    pastReservations: reservations,
    selectedTabId: PAST_TAB_ID,
  }),
});

const getReservationsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const getReservation = (state, { status }) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  reservationStatus: status,
  reservation: {},
  cancellationFeedbackSuccess: false,
  feedbackErrorMessage: '',
});

const getReservationSilent = (state, { status }) => state.merge({
  reservationStatus: status,
  isError: false,
});

const getReservationDone = (state, { reservation }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  reservation,
});

const getReservationError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const postCancelReservation = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const postCancelReservationDone = (state, { reservation }) => state.merge({
  isLoading: false,
  isError: false,
  reservation,
});

const postCancelReservationError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const postCancellationFeedback = (state) => state.merge({
  isLoading: true,
  isError: false,
  feedbackErrorMessage: '',
});

const postCancellationFeedbackDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  cancellationFeedbackSuccess: true,
});

const postCancellationFeedbackError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  feedbackErrorMessage: message,
});

const setSortBy = (state, { sortBy }) => state.merge({
  sortBy,
});

const setBookingsTab = (state, { selectedTabId }) => state.merge({ selectedTabId });

const prepareUpdateReservation = (state) => state.merge({
  isUpdatingReservation: true,
  isUpdatingReservationDone: false,
  updatingReservationError: '',
});

const prepareUpdateReservationDone = (state, { updatedReservation }) => state.merge({
  updatedReservation,
  isUpdatingReservation: false,
  isUpdatingReservationDone: true,
  updatingReservationError: '',
});

const prepareUpdateReservationError = (state, { errorMessage }) => state.merge({
  isUpdatingReservation: false,
  isUpdatingReservationDone: true,
  updatingReservationError: errorMessage,
});

const updateReservation = (state) => state.merge({
  isUpdatingReservation: true,
  isUpdatingReservationDone: false,
  updatingReservationError: '',
});

const updateReservationDone = (state) => state.merge({
  isUpdatingReservation: false,
  isUpdatingReservationDone: true,
  updatingReservationError: '',
});

const updateReservationError = (state, { errorMessage }) => state.merge({
  isUpdatingReservation: false,
  isUpdatingReservationDone: true,
  updatingReservationError: errorMessage,
});

const resetUpdateReservation = (state) => state.merge({
  isUpdatingReservation: false,
  isUpdatingReservationDone: false,
  updatingReservationError: '',
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RESERVATIONS]: getReservations,
  [Types.GET_RESERVATIONS_SILENT]: getReservationsSilent,
  [Types.GET_RESERVATIONS_DONE]: getReservationsDone,
  [Types.GET_RESERVATIONS_ERROR]: getReservationsError,
  [Types.GET_RESERVATION]: getReservation,
  [Types.GET_RESERVATION_SILENT]: getReservationSilent,
  [Types.GET_RESERVATION_DONE]: getReservationDone,
  [Types.GET_RESERVATION_ERROR]: getReservationError,
  [Types.POST_CANCEL_RESERVATION]: postCancelReservation,
  [Types.POST_CANCEL_RESERVATION_DONE]: postCancelReservationDone,
  [Types.POST_CANCEL_RESERVATION_ERROR]: postCancelReservationError,
  [Types.POST_CANCELLATION_FEEDBACK]: postCancellationFeedback,
  [Types.POST_CANCELLATION_FEEDBACK_DONE]: postCancellationFeedbackDone,
  [Types.POST_CANCELLATION_FEEDBACK_ERROR]: postCancellationFeedbackError,
  [Types.SET_BOOKINGS_TAB]: setBookingsTab,
  [Types.SET_SORT_BY_RESERVATIONS]: setSortBy,
  [Types.PREPARE_UPDATE_RESERVATION]: prepareUpdateReservation,
  [Types.PREPARE_UPDATE_RESERVATION_DONE]: prepareUpdateReservationDone,
  [Types.PREPARE_UPDATE_RESERVATION_ERROR]: prepareUpdateReservationError,
  [Types.UPDATE_RESERVATION]: updateReservation,
  [Types.UPDATE_RESERVATION_DONE]: updateReservationDone,
  [Types.UPDATE_RESERVATION_ERROR]: updateReservationError,
  [Types.RESET_UPDATE_RESERVATION]: resetUpdateReservation,
});
